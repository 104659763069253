import { Segmented, Tooltip } from "antd";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames/bind";
import { AppDispatch, RootState } from "../../../app/store";
import { LoaderWrapper } from "../../../components";
import { fetchState } from "..";
import { AbilityContext } from "../../casl";
import { PopoverConfirmation } from "../../../components/PopoverConfirmation";
import { fetchMetrics, updateEStop } from "../slice";
import { LMIconButton } from "../../../components/LMIconButton";
import { IconQuestionCircle } from "../../../assets";
import styles from "./EStopButton.module.css";

const cx = classNames.bind(styles);

export const EStopButton = () => {
  const dispatch = useDispatch<AppDispatch>();
  const ability = useContext(AbilityContext);
  const { state, status } = useSelector((state: RootState) => {
    return state.systemsAttributes;
  });

  const { selectedSystem } = useSelector((state: RootState) => {
    return state.systems;
  });

  const getState = useCallback(async () => {
    if (!selectedSystem?.id) return;
    try {
      await dispatch(fetchMetrics(selectedSystem.id));
      await dispatch(fetchState(selectedSystem.id)).unwrap();
    } catch (error) {
      console.error(error);
    }
    await setTimeout(() => {
      getState();
    }, 2000);
  }, [selectedSystem?.id, dispatch]);

  useEffect(() => {
    getState();
  }, [getState]);

  const { t } = useTranslation("layout");

  const handleUpdateEStop = () => {
    if (!state || !selectedSystem) return;
    dispatch(
      updateEStop({ systemId: selectedSystem.id, status: !state.general.estop })
    );
  };

  return (
    <LoaderWrapper status={status}>
      {state && selectedSystem ? (
        <div className={styles.container}>
          <h6 className={styles.title}>{t("rStop")}</h6>
          <Tooltip placement="bottom" title={t("rStopTip")}>
            <LMIconButton>
              <IconQuestionCircle />
            </LMIconButton>
          </Tooltip>
          <div className={styles.action}>
            <PopoverConfirmation
              onConfirm={handleUpdateEStop}
              confirmText={t("activate")}
              text={
                state.general.estop
                  ? t("rStopDisableWarn")
                  : t("rStopEnableWarn")
              }
            >
              <Segmented
                className={cx({
                  segmented: true,
                  red: state.general.estop === true,
                  green: state.general.estop === false,
                })}
                disabled={
                  state.general.estop === undefined ||
                  !ability.can("update", "estop")
                }
                value={state.general.estop === true ? "enabled" : "disabled"}
                options={[
                  {
                    label:
                      state.general.estop === true
                        ? t("rStopEnabled")
                        : t("rStopEnable"),
                    value: "enabled",
                  },
                  {
                    label:
                      state.general.estop === false
                        ? t("rStopDisabled")
                        : t("rStopDisable"),
                    value: "disabled",
                  },
                ]}
              />
            </PopoverConfirmation>
          </div>
        </div>
      ) : (
        <></>
      )}
    </LoaderWrapper>
  );
};
