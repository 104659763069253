import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ISystemsSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import {
  getState,
  updateEStop as _updateEStop,
  getActivity,
  getSystemStateMetrics,
} from './api';
import { ActivityTypes } from '../systems';

export const initialState: ISystemsSliceState = {
  status: ApiStatuses.initial,
  metricsStatus: ApiStatuses.initial,
  healthStatus: ApiStatuses.success,
  configurationStatus: ApiStatuses.initial,
  metrics: [],
};

export const fetchState = createAsyncThunk(
  'systemsAttributes/fetchState',
  async (systemId: number) => {
    const response = await getState(systemId);
    return response.data;
  }
);

export const fetchMetrics = createAsyncThunk(
  'systemsAttributes/fetchMetrics',
  async (systemId: number) => {
    const response = await getSystemStateMetrics(systemId);
    return response.data;
  }
);

export const fetchActivity = createAsyncThunk(
  'systemsAttributes/fetchActivity',
  async ({ tenantId, startTime, endTime, types }: { tenantId: number, startTime: Date, endTime: Date, types: ActivityTypes[] }) => {
    const response = await getActivity(tenantId, startTime, endTime, types);
    return response.data;
  }
);

export const updateEStop = createAsyncThunk(
  'systems/updateEStop',
  async ({ systemId, status}: { systemId: number, status: boolean }) => {
    const response = await _updateEStop(systemId, status);
    return response.data;
  }
);

const slice = createSlice({
  name: "systemsAttributes",
  initialState,
  reducers: {
    setSystemId(state: ISystemsSliceState, action: PayloadAction<number>) {
      state.systemId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchActivity
      .addCase(fetchActivity.fulfilled, (state, action) => {
        if (JSON.stringify(action.payload) === JSON.stringify(state.activity)) return;
        state.activity = action.payload;
      })
      // fetchMetrics
      .addCase(fetchMetrics.fulfilled, (state, action) => {
        state.metricsStatus = ApiStatuses.success;
        state.metrics = action.payload;
      })
      // fetchMetrics
      .addCase(fetchMetrics.rejected, (state) => {
        state.metricsStatus = ApiStatuses.fail;
      })
      // fetchState
      .addCase(fetchState.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.state = action.payload;
      })
      .addCase(fetchState.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // updateEStop
      .addCase(updateEStop.fulfilled, (state) => {
        if (state.state) {
          state.state.general.estop = !state.state.general.estop;
        }
      })
  },
});

export const systemsAttributes = slice.reducer;
export const actions = slice.actions;