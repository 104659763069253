import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMaintenanceSliceState } from './types';
import { ApiStatuses } from '../../app/types';
import { getMaintenances, getCompare, getOperation, getProducts, downloadReport, getIndexingRequestsList } from './api';

const initialState: IMaintenanceSliceState = {
  maintenances: [],
  operations: [],
  products: [],
  indexing: [],
  page: 1,
  total: 0,
  status: ApiStatuses.initial,
  operationsStatus: ApiStatuses.initial,
  compareStatus: ApiStatuses.initial,
  productsStatus: ApiStatuses.initial,
  downloadStatus: ApiStatuses.initial,
  indexingStatus: ApiStatuses.initial,
};

export const fetchMaintenances = createAsyncThunk(
  'maintenance/fetchMaintenances',
  async ({ systemId, page }: { systemId: number, page: number }) => {
    const response = await getMaintenances(systemId, page, 10);
    return response.data;
  }
);

export const fetchOperations = createAsyncThunk(
  'maintenance/fetchOperations',
  async ({ maintenanceId }: { maintenanceId:  string }) => {
    const response = await getOperation(maintenanceId);
    return response.data;
  }
);

export const fetchCompare = createAsyncThunk(
  'maintenance/fetchCompare',
  async ({ maintenanceId }: { maintenanceId:  string }) => {
    const response = await getCompare(maintenanceId);
    return response.data;
  }
);

export const fetchProducts = createAsyncThunk(
  'maintenance/fetchProducts',
  async ({ operationId }: { operationId:  number }) => {
    const response = await getProducts(operationId);
    return response.data;
  }
);

export const fetchReport = createAsyncThunk(
  'maintenance/fetchReport',
  async ({ maintenanceId }: { maintenanceId:  string }) => {
    const response = await downloadReport(maintenanceId);
    return response.data;
  }
);

export const fetchIndexing = createAsyncThunk(
  'maintenance/fetchIndexing',
  async ({ maintenanceId }: { maintenanceId:  string }) => {
    const response = await getIndexingRequestsList(maintenanceId);
    return response.data;
  }
);

const slice = createSlice({
  name: "maintenance",
  initialState,
  reducers: {
    setSelectedMaintenanceId(state: IMaintenanceSliceState, action: PayloadAction<string | undefined>) {
      state.selectedMaintenanceId = action.payload;
    },
    setPage(state: IMaintenanceSliceState, action: PayloadAction<number>) {
      state.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchMaintenances
      .addCase(fetchMaintenances.pending, (state) => {
        state.status = ApiStatuses.loading;
      })
      .addCase(fetchMaintenances.fulfilled, (state, action) => {
        state.status = ApiStatuses.success;
        state.maintenances = action.payload.values;
        state.total = action.payload.count;
      })
      .addCase(fetchMaintenances.rejected, (state) => {
        state.status = ApiStatuses.fail;
      })
      // fetchOperations
      .addCase(fetchOperations.pending, (state) => {
        state.operationsStatus = ApiStatuses.loading;
      })
      .addCase(fetchOperations.fulfilled, (state, action) => {
        state.operationsStatus = ApiStatuses.success;
        state.operations = action.payload;
      })
      .addCase(fetchOperations.rejected, (state) => {
        state.operationsStatus = ApiStatuses.fail;
      })
      // fetchCompare
      .addCase(fetchCompare.pending, (state) => {
        state.compareStatus = ApiStatuses.loading;
      })
      .addCase(fetchCompare.fulfilled, (state, action) => {
        state.compareStatus = ApiStatuses.success;
        state.compare = action.payload;
      })
      .addCase(fetchCompare.rejected, (state) => {
        state.compareStatus = ApiStatuses.fail;
      })
      // fetchProducts
      .addCase(fetchProducts.pending, (state) => {
        state.productsStatus = ApiStatuses.loading;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.productsStatus = ApiStatuses.success;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.productsStatus = ApiStatuses.fail;
      })
      // fetchReport
      .addCase(fetchReport.pending, (state) => {
        state.downloadStatus = ApiStatuses.loading;
      })
      .addCase(fetchReport.fulfilled, (state) => {
        state.downloadStatus = ApiStatuses.success;
      })
      .addCase(fetchReport.rejected, (state) => {
        state.downloadStatus = ApiStatuses.fail;
      })
      // fetchIndexing
      .addCase(fetchIndexing.pending, (state) => {
        state.indexingStatus = ApiStatuses.loading;
      })
      .addCase(fetchIndexing.fulfilled, (state, action) => {
        state.indexingStatus = ApiStatuses.success;
        state.indexing = action.payload.values;
      })
      .addCase(fetchIndexing.rejected, (state) => {
        state.indexingStatus = ApiStatuses.fail;
      })
  },
});

export const maintenance = slice.reducer;
export const actions = slice.actions;