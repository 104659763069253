import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconSystemTasks } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { LMNewButton } from "../../../components/LMNewButton";
import { EnabledTaskConfiguration } from "../types";
import { SystemTasksModalContent } from "./SystemTasksModalContent";
import {
  getIconBackgroundColor,
  getIconColor,
  getSystemTasksNames,
} from "./utils";
import { FormModal } from "../../../components/FormModal";
import { updateSystemTasks } from "../../systems/api";
import { AbilityContext } from "../../casl";

interface Props {
  systemId: number;
  enabledTasks: EnabledTaskConfiguration;
}

export const SystemTasks: FC<Props> = ({ systemId, enabledTasks }) => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [values, setValues] = useState(enabledTasks);
  const [defaultValues, setDefaultValues] = useState(enabledTasks);
  const { t } = useTranslation("systemTasks");
  const ability = useContext(AbilityContext);

  const handleConfirm = async () => {
    await updateSystemTasks(systemId, values);
    handleCloseConfirmationModal();
  };

  const handleChangeTasks = () => setConfirmModalOpen(true);
  const handleCloseConfirmationModal = () => setConfirmModalOpen(false);

  const handleChangeValues = (values: EnabledTaskConfiguration) => {
    setValues(values);
  };

  useEffect(() => {
    setDefaultValues(enabledTasks);
  }, [enabledTasks]);

  return (
    <>
      <CardContainer
        icon={
          <IconWithBackground
            icon={<IconSystemTasks stroke={getIconColor(enabledTasks)} />}
            color={getIconBackgroundColor(enabledTasks)}
          />
        }
        title={t("title")}
        subtitle={getSystemTasksNames(enabledTasks, t)}
      >
        <LMNewButton
          color="default"
          onClick={handleChangeTasks}
          small
          disabled={!ability.can("update", "systems")}
        >
          {t("action")}
        </LMNewButton>
      </CardContainer>
      <FormModal
        onConfirm={handleConfirm}
        submitText={t("apply")}
        content={
          <SystemTasksModalContent
            key={systemId}
            values={values}
            defaultValues={defaultValues}
            onChange={handleChangeValues}
            enabledTasks={enabledTasks}
          />
        }
        open={confirmModalOpen}
        onCancel={handleCloseConfirmationModal}
      />
    </>
  );
};
