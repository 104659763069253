import { TFunction } from "i18next";
import { EnabledTaskConfiguration } from "../types";

const getActiveTasks = (enabledTasks: EnabledTaskConfiguration) => {
  const tasks = Object.keys(enabledTasks).map(key => {
    const task = key as keyof EnabledTaskConfiguration;
    return { task: task, value: enabledTasks[task] };
  });
  return tasks.filter(t => t.value);
}

export const getSystemTasksNames = (enabledTasks: EnabledTaskConfiguration, t: TFunction) => {
  const activeTasks = getActiveTasks(enabledTasks);
  if (activeTasks.length === 0) return t("empty");
  return activeTasks.map((task) => t(task.task)).join(", ");
};

export const getIconColor = (enabledTasks: EnabledTaskConfiguration) => {
  const activeTasks = getActiveTasks(enabledTasks);
  if (activeTasks.length === 0) {
    return "var(--color-red-70)";
  }
  if (activeTasks.length === Object.keys(enabledTasks).length) {
    return "var(--color-green-70)";
  }
  return "var(--color-yellow-70)";
};

export const getIconBackgroundColor = (enabledTasks: EnabledTaskConfiguration) => {
  const activeTasks = getActiveTasks(enabledTasks);
  if (activeTasks.length === 0) {
    return "red";
  }
  if (activeTasks.length === Object.keys(enabledTasks).length) {
    return "green";
  }
  return "yellow";
};