import { FC, ReactNode } from "react";
import { ConfigProvider, Layout } from "antd";
import { LMHeader } from "../LMHeader";
import { Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { LMSider } from "../LMSider";
import { ISystemDto } from "../../modules/systems";
import { useTranslation } from "react-i18next";
import styles from "./LMLayout.module.css";

const { Content, Header } = Layout;

interface Props {
  children: ReactNode;
}

const isSystemValid = (
  systems: ISystemDto[],
  systemStringId: string | undefined
) => {
  if (!systemStringId) return false;
  const systemId = parseInt(systemStringId, 0);
  return systems.some((s) => s.id === systemId);
};

export const LMLayout: FC<Props> = ({ children }) => {
  const params = useParams();
  const { systems, selectedSystem } = useSelector((state: RootState) => {
    return state.systems;
  });
  const { i18n } = useTranslation();

  if (selectedSystem && !isSystemValid(systems, params.systemId)) {
    return <Navigate to={`/${selectedSystem.id}/`} />;
  }

  return (
    <ConfigProvider direction={i18n.language === "en" ? "ltr" : "rtl"}>
      <Layout
        className={`${styles.container} ${
          i18n.language === "en" ? "ltr" : "rtl"
        }`}
        dir={i18n.language === "en" ? "ltr" : "rtl"}
      >
        <LMSider />
        <Layout className="site-layout">
          <Header className={styles.header}>
            <LMHeader />
          </Header>
          <Content className={styles.content}>{children}</Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
