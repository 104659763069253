import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {  IControlSliceState } from './types';
import { getControlCommands } from './api';

export const initialState: IControlSliceState = {
  searchString: "",
  commands: []
};

export const fetchControlCommands = createAsyncThunk(
  'control/fetchControlCommands',
  async (serialNumber: string) => {
    const response = await getControlCommands(serialNumber);
    return response.data;
  }
);

const slice = createSlice({
  name: "control",
  initialState,
  reducers: {
    setFilters(state: IControlSliceState, action: PayloadAction<{ searchString: string }>) {
      state.searchString = action.payload.searchString;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchControlCommands
      .addCase(fetchControlCommands.fulfilled, (state, action) => {
        state.commands = action.payload;
      })
  },
});

export const control = slice.reducer;
export const actions = slice.actions;