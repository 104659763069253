import { FC } from "react";
import { Drawer, Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../app/store";
import { actions } from "../slice";
import { Compare } from "./Compare";
import { OperationsAndProducts } from "./OperationsAndProducts";
import { ReplenishmentDrawerHeader } from "./ReplenishmentDrawerHeader";
import { IndexingTab } from "./IndexingTab";
import styles from "./ReplenishmentDrawer.module.css";

interface Props {
  maintenanceId?: string;
  systemId: number;
}

export const ReplenishmentDrawer: FC<Props> = ({ maintenanceId, systemId }) => {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation("maintenance");

  const { downloadStatus } = useSelector((state: RootState) => {
    return state.maintenance;
  });

  const handleResetSearchParams = (visible: boolean) => {
    if (!visible) setSearchParams({});
  };

  const onCloseHandler = () => {
    dispatch(actions.setSelectedMaintenanceId(undefined));
  };

  return (
    <Drawer
      open={!!maintenanceId}
      title={
        <ReplenishmentDrawerHeader
          maintenanceId={maintenanceId}
          downloadStatus={downloadStatus}
          onCloseHandler={onCloseHandler}
        />
      }
      contentWrapperStyle={{
        width: "80%",
      }}
      className={styles.drawer}
      maskStyle={{
        background: "rgba(0, 0, 0, 0.20)",
      }}
      afterOpenChange={handleResetSearchParams}
      closable={false}
      bodyStyle={{
        padding: "56px",
        backgroundColor: "var(--color-background)",
        display: "flex",
        flexDirection: "column",
      }}
      onClose={onCloseHandler}
    >
      <Tabs
        items={[
          {
            label: t("compareTitle"),
            key: "compare",
            children: maintenanceId ? (
              <Compare maintenanceId={maintenanceId} />
            ) : (
              <></>
            ),
          },
          {
            label: t("operations"),
            key: "operations",
            children: maintenanceId ? (
              <OperationsAndProducts maintenanceId={maintenanceId} />
            ) : (
              <></>
            ),
          },
          {
            label: t("indexing"),
            key: "indexing",
            children: maintenanceId ? (
              <IndexingTab maintenanceId={maintenanceId} systemId={systemId} />
            ) : (
              <></>
            ),
          },
        ]}
      ></Tabs>
    </Drawer>
  );
};
