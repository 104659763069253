import { FC, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IMaintenance } from "../types";
import Table, { ColumnType } from "antd/lib/table";
import { actions } from "../slice";
import { useDispatch } from "react-redux";
import { format } from "date-fns";
import { ApiStatuses } from "../../../app/types";
import { DATE_TIME_NO_SECONDS_FORMAT } from "../../../app/constants";

interface Props {
  list: IMaintenance[];
  total: number;
  page: number;
  status: ApiStatuses;
}

export const ReplenishmentList: FC<Props> = ({ list, total, page, status }) => {
  const dispatch = useDispatch();
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation("maintenance");

  const columns = useMemo(
    () => [
      {
        title: t("id"),
        dataIndex: "id",
        key: "id",
      },
      {
        title: t("key"),
        dataIndex: "key",
        key: "key",
        render: (key: string, maintenance: IMaintenance) => {
          return maintenance.maintenanceKey?.name;
        },
      },
      {
        title: t("in"),
        key: "in",
        render: (inAmount: number, maintenance: IMaintenance) => {
          return maintenance.operations.filter((o) => o.type === "Loading")
            .length;
        },
      },
      {
        title: t("out"),
        key: "out",
        render: (outAmount: number, maintenance: IMaintenance) => {
          return maintenance.operations.filter((o) => o.type === "Unloading")
            .length;
        },
      },
      {
        title: t("started"),
        render: (maintenance: IMaintenance) => {
          return maintenance.started
            ? format(new Date(maintenance.started), DATE_TIME_NO_SECONDS_FORMAT)
            : "";
        },
      },
      {
        title: t("ended"),
        render: (maintenance: IMaintenance) => {
          return maintenance.ended
            ? format(new Date(maintenance.ended), DATE_TIME_NO_SECONDS_FORMAT)
            : "";
        },
      },
    ],
    [t]
  ) as ColumnType<IMaintenance>[];

  const onClickRow = (maintenance: IMaintenance) => {
    dispatch(actions.setSelectedMaintenanceId(maintenance.id));
    setSearchParams({ maintenanceId: maintenance.id });
  };

  const handlePageChange = (updatedPage: number) => {
    dispatch(actions.setPage(updatedPage));
  };

  return (
    <Table
      dataSource={list}
      columns={columns}
      rowKey="id"
      loading={status === ApiStatuses.loading}
      onRow={(row) => ({
        onClick: () => onClickRow(row),
        style: { cursor: "pointer" },
      })}
      pagination={{
        showSizeChanger: false,
        size: "small",
        current: page,
        onChange: handlePageChange,
        pageSize: 10,
        total,
      }}
    />
  );
};
