import { useTranslation } from "react-i18next";
import { IconAlertTriangle } from "../../../assets";
import { Button2 } from "../../../components/Typography";
import styles from "./StateErrorBox.module.css";

export const StateErrorBox = () => {
  const { t } = useTranslation("systems");

  return (
    <div className={styles.container}>
      <IconAlertTriangle />
      <Button2 className={styles.text}>{t("stateError")}</Button2>
    </div>
  );
};
