import { FC, useEffect, useState } from "react";
import { Layout } from "antd";
import classNames from "classnames/bind";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowLeft, IconAnchorLeft, IconLogout, Logo } from "../../assets";
import { LMIconButton } from "../../components/LMIconButton";
import { Navigation } from "../../modules/navigation";
import { SystemsSelector } from "../../modules/systems";
import { useLocation, useNavigate } from "react-router-dom";
import { LMNewButton } from "../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import { clearUser } from "../../modules/auth";
import styles from "./LMSider.module.css";

const cx = classNames.bind(styles);

const { Sider } = Layout;

export const LMSider: FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth0();
  const location = useLocation();
  const { t } = useTranslation("layout");
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname.includes("monitoring") &&
      location.search.includes("viewType=streams")
    ) {
      setCollapsed(true);
    }
  }, [location.pathname, location.search]);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const isProfile = location.pathname.includes("/profile");

  const handleLogout = () => {
    clearUser();
    logout();
  };

  return (
    <Sider
      breakpoint="lg"
      className={styles.sider}
      theme="light"
      collapsible
      collapsedWidth={66}
      width={230}
      collapsed={collapsed}
    >
      <div className={cx({ "header-container": true, collapsed })}>
        <div className={styles.header}>
          <Logo className={cx({ logo: true, collapsed })} />
          <LMIconButton onClick={handleCollapse}>
            <ArrowLeft className={cx({ "collapse-icon": true, collapsed })} />
          </LMIconButton>
        </div>
      </div>
      <div className={cx({ container: true, "is-profile": isProfile })}>
        {isProfile && (
          <LMNewButton
            color="default"
            onClick={() => navigate(-1)}
            className={styles["back-button"]}
          >
            <IconAnchorLeft />
            {t("back")}
          </LMNewButton>
        )}
        <Navigation collapsed={collapsed} isOrganization />
        {!isProfile && (
          <>
            <div className={styles["select-container"]}>
              <SystemsSelector collapsed={collapsed} />
            </div>
            <Navigation collapsed={collapsed} />
          </>
        )}
        {isProfile && (
          <div className={styles["logout-button-container"]}>
            <LMNewButton
              color="transparent"
              onClick={handleLogout}
              className={styles["logout-button"]}
            >
              <IconLogout stroke="var(--color-gray-60)" />
              {t("logout")}
            </LMNewButton>
          </div>
        )}
      </div>
    </Sider>
  );
};
