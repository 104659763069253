import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { IconSearchSmall, IconStream } from "../../../assets";
import { LMNewInput } from "../../../components";
import { LMNewButton } from "../../../components/LMNewButton";
import styles from "./ControlFilters.module.css";
import { Can } from "../../casl";

interface Props {
  searchString: string;
  systemId: number;
}

export const ControlFilters: FC<Props> = ({ searchString, systemId }) => {
  const { t } = useTranslation("control");
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNameSearchChange = (evt: SyntheticEvent) => {
    const target = evt.target as HTMLInputElement;
    searchParams.set("searchString", target.value);
    setSearchParams(searchParams);
  };

  const handleStream = () => {
    window.open(
      `${window.location.origin}/${systemId}/monitoring?viewType=streams`
    );
  };

  return (
    <div className={styles.container}>
      <LMNewInput
        placeholder={t("searchPlaceholder")}
        onChange={handleNameSearchChange}
        icon={<IconSearchSmall />}
        value={searchString}
      />
      <Can I="get" a="media">
        <div className={styles["button-container"]}>
          <LMNewButton
            iconRight={<IconStream stroke="var(--color-white)" />}
            onClick={handleStream}
          >
            {t("stream")}
          </LMNewButton>
        </div>
      </Can>
    </div>
  );
};
