import { FC, useCallback, useEffect } from "react";
import { Alert, Empty, Table } from "antd";
import { ICodeStockCompare } from "../types";
import { comparator, getMainColumns } from "./utils";
import { useTranslation } from "react-i18next";
import styles from "./ReplenishmentDrawer.module.css";
import { AppDispatch, RootState } from "../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompare } from "../slice";
import { LoaderWrapper } from "../../../components";

interface Props {
  maintenanceId: string;
}

export const Compare: FC<Props> = ({ maintenanceId }) => {
  const { t } = useTranslation("maintenance");
  const dispatch = useDispatch<AppDispatch>();

  const { compare, compareStatus } = useSelector((state: RootState) => {
    return state.maintenance;
  });

  const getCompare = useCallback(() => {
    dispatch(fetchCompare({ maintenanceId }));
  }, [dispatch, maintenanceId]);

  useEffect(() => {
    getCompare();
  }, [getCompare]);

  return (
    <LoaderWrapper status={compareStatus}>
      {compare && compare.comparesByShippingCode ? (
        <div className={styles["compare-container"]}>
          {Object.keys(compare.comparesByShippingCode).map((code) => {
            const compareByShippingCode = compare.comparesByShippingCode?.[
              code
            ] as ICodeStockCompare;
            let type: "success" | "info" | "warning" | "error" = "info";
            let description = null;
            switch (compareByShippingCode?.result.value) {
              case "Missing data":
                type = "warning";
                break;
              case "Diff found":
                type = "error";
                description = (
                  <Table
                    pagination={false}
                    showHeader={false}
                    columns={getMainColumns(t)}
                    dataSource={compareByShippingCode.stockCompareProducts
                      .slice()
                      .filter((p) => p.diff !== 0)
                      .sort(comparator)}
                  />
                );
                break;
              case "Accepted":
                type = "success";
                break;
            }

            const headline = (
              <div className={styles["compare-head"]}>
                <h2 className={styles.code}>{code}</h2>
                <h2 className={styles.result}>
                  {compareByShippingCode.result.value}
                </h2>
              </div>
            );

            return (
              <Alert
                key={code}
                className={styles.alert}
                message={headline}
                description={description}
                type={type}
                showIcon
              />
            );
          })}
        </div>
      ) : (
        <Empty />
      )}
    </LoaderWrapper>
  );
};
