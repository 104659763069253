import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { CardContainer } from "../../../components/CardContainer";
import { IconWithBackground } from "../../../components/IconWithBackground";
import { IconSystemAutoMode } from "../../../assets/Sprites";
import { EControlStatus } from "../../control/types";
import { LMNewButton } from "../../../components/LMNewButton";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { H3 } from "../../../components/Typography";
import { useAppNotifications } from "../../../components/LMNotifications";
import { switchMode } from "../../control";

interface Props {
  serialNumber: string;
}

export const SystemMode: FC<Props> = ({ serialNumber }) => {
  const { t } = useTranslation("systemMode");
  const { t: tControl } = useTranslation("control");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { contextHolder, success, warning, error } = useAppNotifications();

  const status = useSelector((state: RootState) => {
    return state.systemsAttributes.state?.remoteControl?.status;
  });

  const isManualMode = useCallback(() => {
    return status === EControlStatus.Running;
  }, [status]);

  const handleManualMode = () => {
    setConfirmModalOpen(true);
  };

  const handleAutoMode = async () => {
    try {
      await switchMode(serialNumber, false);
      setConfirmModalOpen(false);
      success(tControl("success"));
    } catch {
      error(tControl("error"));
    }
  };

  const handleSwitchToManual = async () => {
    try {
      await switchMode(serialNumber, true);
      setConfirmModalOpen(false);
      warning(tControl("warning"));
    } catch {
      error(tControl("error"));
    }
  };

  return (
    <>
      {contextHolder}
      <CardContainer
        icon={
          <IconWithBackground
            icon={
              <IconSystemAutoMode
                stroke={isManualMode() ? "var(--color-yellow-70)" : undefined}
              />
            }
            color={isManualMode() ? "yellow" : "green"}
          />
        }
        title={isManualMode() ? t("titleInactive") : t("titleActive")}
        subtitle={isManualMode() ? t("subtitleInactive") : t("subtitleActive")}
      >
        {isManualMode() ? (
          <LMNewButton color="default" onClick={handleAutoMode} small>
            {t("back")}
          </LMNewButton>
        ) : (
          <LMNewButton color="default" onClick={handleManualMode} small>
            {t("action")}
          </LMNewButton>
        )}
      </CardContainer>
      <ConfirmationModal
        content={<H3>{tControl("manualConfirmation")}</H3>}
        confirmText={tControl("confirm")}
        open={confirmModalOpen}
        onConfirm={handleSwitchToManual}
        onCancel={() => setConfirmModalOpen(false)}
      />
    </>
  );
};
