import { ApiStatuses } from "../../app/types";
import { IMaintenance } from "../maintenance";

export enum IndexingRequestStatus {
  Pending = "Pending",
  Approved = "Approved",
  InProgress = "InProgress",
  Completed = "Completed",
  Failed = "Failed"
}

export enum IndexingTypes {
  Indexed = "Indexed",
  Unknown = "Unknown",
  UnRecognized = "UnRecognized",
  Missing = "Missing",
}

export interface IIndexingRequest {
  id: number;
  triggerType: "Auto" | "Manual";
  status: IndexingRequestStatus;
  createdOn: string;
}

export interface ISlotStockInfo {
  sku?: string;
  quantity: number;
  valid: boolean;
}

export interface IIndexCellProduct {
  indexId: number;
  palletId: string;
  slotLevel: number;
  slotColumn: number;
  valid: boolean;
  error?: string;
  before: ISlotStockInfo;
  after: ISlotStockInfo;
}

export interface IIndexPalletRequest {
  palletId: string;
  side?: "A" | "B"
  row: number;
  column: number;
}

export interface IIndexingRequestDetails {
  id: number;
  indexedProducts: IIndexCellProduct[];
  maintenanceId?: number;
  indexPalletRequests: IIndexPalletRequest[];
  status: IndexingRequestStatus;
  systemId: number;
  triggerType: "Auto" | "Manual";
}

export interface IIndexingSliceState {
  list: IIndexingRequest[];
  maintenances: IMaintenance[];
  status: ApiStatuses;
  detailsStatus: ApiStatuses;
  requestDetails?: IIndexingRequestDetails;
  isCreateDrawerOpen: boolean;
  selectedIndexingRequestId?: number;
  page: number;
  total: number;
}

export interface IIndexingRange {
  palletId: string;
  side?: "A" | "B";
  row?: number;
  column?: number;
}
export interface IIndexingRangeForm extends IIndexingRange {
  id: number;
}

export interface ICreateIndexingRequestValues {
  maintenanceId: string;
  ranges: IIndexingRange[];
}