import { FC, useCallback, useEffect, useState } from "react";
import {
  ControlCommands,
  ControlMode,
  controlActions,
  fetchControlCommands,
  switchMode,
  isControlModeAutoOrPending,
} from "../../modules/control";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../app/store";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IconCancelOrder,
  IconManualControlAction,
  IconPlayCircle,
} from "../../assets";
import { LMNewButton } from "../../components/LMNewButton";
import { useAppNotifications } from "../../components/LMNotifications";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { H3 } from "../../components/Typography";
import { EControlStatus } from "../../modules/control/types";
import styles from "./Control.module.css";

interface Props {
  serialNumber: string;
  systemId: number;
}

export const Control: FC<Props> = ({ serialNumber, systemId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation("control");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { contextHolder, success, warning, error } = useAppNotifications();

  const { commands, searchString } = useSelector((state: RootState) => {
    return state.control;
  });

  const remoteControl = useSelector((state: RootState) => {
    return state.systemsAttributes.state?.remoteControl;
  });

  const getControlData = useCallback(() => {
    dispatch(fetchControlCommands(serialNumber));
  }, [dispatch, serialNumber]);

  useEffect(() => {
    dispatch(
      controlActions.setFilters({
        searchString: searchParams.get("searchString") || "",
      })
    );
  }, [searchParams, dispatch]);

  useEffect(() => {
    getControlData();
  }, [getControlData]);

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
  };

  const handleOpenConfirmationModal = () => {
    setConfirmModalOpen(true);
  };

  const handleSwitchToManual = async () => {
    try {
      await switchMode(serialNumber, true);
      warning(t("warning"));
      handleCloseConfirmationModal();
    } catch {
      error(t("error"));
    }
  };

  const handleSwitchToAuto = async () => {
    try {
      await switchMode(serialNumber, false);
      success(t("success"));
    } catch {
      error(t("error"));
    }
  };

  const handleAutoSuccess = () => {
    success(t("success"));
  };
  const handleSwitchError = () => error(t("error"));

  return (
    <div className={styles.container}>
      <div className={styles["mode-container"]}>
        <ControlMode
          mode={remoteControl}
          serialNumber={serialNumber}
          onConfirmationClose={handleCloseConfirmationModal}
          onConfirmationOpen={handleOpenConfirmationModal}
          onAutoSuccess={handleAutoSuccess}
          onError={handleSwitchError}
        />
      </div>
      {isControlModeAutoOrPending(remoteControl) ? (
        <div className={styles["auto-mode"]}>
          <div className={styles["auto-mode-content"]}>
            <div className={styles["auto-mode-icon"]}>
              <IconPlayCircle />
            </div>
            {remoteControl?.status === EControlStatus.Pending ? (
              <>
                {t("pendingMode")}
                <LMNewButton color="danger" onClick={handleSwitchToAuto}>
                  {t("cancel")}
                  <IconCancelOrder />
                </LMNewButton>
              </>
            ) : (
              <>
                {t("autoModeEnabled")}
                <LMNewButton
                  onClick={handleOpenConfirmationModal}
                  iconRight={
                    <IconManualControlAction stroke="var(--color-white)" />
                  }
                >
                  {t("switchToManual")}
                </LMNewButton>
              </>
            )}
          </div>
        </div>
      ) : (
        <ControlCommands
          systemId={systemId}
          commands={commands}
          serialNumber={serialNumber}
          searchString={searchString}
        />
      )}
      <ConfirmationModal
        content={<H3>{t("manualConfirmation")}</H3>}
        confirmText={t("confirm")}
        open={confirmModalOpen}
        onConfirm={handleSwitchToManual}
        onCancel={handleCloseConfirmationModal}
      />
      {contextHolder}
    </div>
  );
};
