import { EControlStatus, IControlCommand, IManualControl, TControlCommandGrouped } from "./types";

export const isControlModeAuto = (mode?: IManualControl) => {
  if (!mode?.status) return true;
  return false;
}

export const isControlModeAutoOrPending = (mode?: IManualControl) => {
  if (!mode?.status || mode.status === EControlStatus.Pending) return true;
  return false;
}

export const groupCommandBySystem = (commands: IControlCommand[]): TControlCommandGrouped => {
  const grouped: TControlCommandGrouped = {};
  commands.forEach(command => {
    if (grouped[command.commandSystem]) {
      grouped[command.commandSystem].push(command);
      return;
    }
    grouped[command.commandSystem] = [command];
  });
  return grouped;
}