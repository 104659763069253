import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { MenuProps } from "rc-menu";
import { Menu } from "antd";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  findItemKeyByRoute,
  findSubKeyByRoute,
  getItems,
  getRegularItems,
} from "../utils";
import { useTranslation } from "react-i18next";
import { INavigationItem, INavigationItemSimple } from "../types";
import { AbilityContext } from "../../casl";
import styles from "./Navigation.module.css";

interface Props {
  collapsed: boolean;
  isOrganization?: boolean;
}

export const Navigation: FC<Props> = memo(
  ({ collapsed, isOrganization = false }) => {
    const params = useParams();
    const [key, setKey] = useState("");
    const ability = useContext(AbilityContext);
    const [openKey, setOpenKey] = useState("");
    const { t } = useTranslation("navigation");
    const location = useLocation();

    const getChildren = (items: INavigationItemSimple[]) => {
      if (items.length === 0) return;
      return items.map((item) => ({
        label: <Link to={getRoute(item)}>{t(item.translationKey)}</Link>,
        key: item.translationKey,
      }));
    };

    const getPrefix = useCallback(() => {
      return `/${params.systemId}`;
    }, [params]);

    const getRoute = (item: INavigationItemSimple) => {
      if ((!item.route || item.tenantLevel) && !ability.can("get", "systems"))
        return item.route;
      return `${getPrefix()}${item.route}`;
    };

    const items: MenuProps["items"] = getItems(
      isOrganization,
      ability,
      location.pathname
    )
      .map((item) => {
        return {
          ...item,
          nestedItems: item.nestedItems,
        };
      })
      .map((item) => {
        return {
          label: (
            <Link
              style={{ pointerEvents: item.route ? "all" : "none" }}
              to={getRoute(item)}
            >
              {t(item.translationKey)}
            </Link>
          ),
          key: item.translationKey,
          icon: item.icon,
          children: getChildren(item.nestedItems),
        };
      });

    const handleOpenKeyChange = (keys: string[]) => {
      const keysReversed = [...keys].reverse();
      const key = keysReversed.find((k) => {
        return getRegularItems(ability).find(
          (i: INavigationItem) =>
            i.translationKey === k && i.nestedItems.length > 0
        );
      });
      setOpenKey(key || "");
    };

    useEffect(() => {
      const activeItem = findItemKeyByRoute(
        location.pathname.replace(getPrefix(), ""),
        ability
      );
      if (!activeItem) return;
      setKey(activeItem.translationKey);
    }, [location.pathname, getPrefix, ability]);

    useEffect(() => {
      if (collapsed) return;
      const activeSub = findSubKeyByRoute(location.pathname, ability);
      if (!activeSub || activeSub.nestedItems.length === 0) return;
      setOpenKey(activeSub.translationKey);
    }, [collapsed, location.pathname, ability]);

    return (
      <Menu
        prefix="test"
        theme="light"
        onOpenChange={handleOpenKeyChange}
        openKeys={collapsed ? undefined : [openKey]}
        items={items}
        mode="inline"
        className={styles.menu}
        selectedKeys={[key]}
      />
    );
  }
);
