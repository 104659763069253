import { Nullable } from "../../app/types";

export enum EControlStatus {
  Pending = "Pending",
  Running = "Running",
}

export enum EControlMode {
  Manual = "Manual",
  Auto = "Auto",
  Idle = "Idle",
}

export interface IManualControl {
  status: Nullable<EControlStatus>;
}

export interface IControlCommand {
  commandName: string;
  commandSystem: string;
  commandDescription: string;
  fieldsDescription: Record<string, string>;
  parameters: Record<string, string>;
}

export interface IControlSliceState {
  commands: IControlCommand[];
  searchString: string;
}

export interface IRunCommand {
  commandSystem: string;
  name: string;
  parameters: Record<string, string>;
}

export type TControlCommandGrouped = Record<string, IControlCommand[]>;