
import { GetTokenSilentlyOptions, IdToken } from '@auth0/auth0-react';
import { scopes } from './scopes';

const USER_KEY = 'auth-user';
const LAST_PATHNAME = 'last-pathname';

export const setUser = (user: IdToken) => localStorage.setItem(USER_KEY, JSON.stringify(user));

export const getUser = (): IdToken | undefined => {
  const user = localStorage.getItem(USER_KEY);
  return user ? JSON.parse(user) : undefined;
};

export const clearUser = () => localStorage.removeItem(USER_KEY);

export const getToken = async (getAccessTokenSilently: (options: GetTokenSilentlyOptions) => Promise<string>) => {
  return await getAccessTokenSilently({
    audience: "solaris-api",
    scope: scopes.join(" "),
  });
};

export const saveLastPathname = (pathname: string) => {
  localStorage.setItem(LAST_PATHNAME, pathname);
}

export const getLastPathname = () => {
  return localStorage.getItem(LAST_PATHNAME);
}

export const clearLastPathname = () => localStorage.removeItem(LAST_PATHNAME);
