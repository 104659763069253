import { FC } from "react";
import { useTranslation } from "react-i18next";
import { LMNewSwitch } from "../../../components/LMNewSwitch";
import { Button2, H3 } from "../../../components/Typography";
import { EnabledTaskConfiguration } from "../types";
import styles from "./SystemTasks.module.css";
import { EActiveTasks } from "../../systems";

interface Props {
  values: EnabledTaskConfiguration;
  defaultValues: EnabledTaskConfiguration;
  onChange: (values: EnabledTaskConfiguration) => void;
  enabledTasks: EnabledTaskConfiguration;
}

export const SystemTasksModalContent: FC<Props> = ({
  values,
  defaultValues,
  onChange,
  enabledTasks,
}) => {
  const { t } = useTranslation("systemTasks");

  return (
    <div className={styles["confirmation-container"]}>
      <div className={styles["confirmation-title"]}>
        <H3>{t("title")}</H3>
      </div>
      <div className={styles["switch-group"]}>
        {Object.keys(enabledTasks).map((key: string) => {
          const task = key as EActiveTasks;
          return (
            <div className={styles["switch-line"]} key={task}>
              <Button2>{t(task)}</Button2>
              <LMNewSwitch
                defaultChecked={defaultValues[task]}
                onChange={() =>
                  onChange({
                    ...values,
                    [task]: !values[task] as boolean,
                  })
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
