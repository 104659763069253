import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { IconHealthy, IconUnhealthy } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { IconWithBackground } from "../../../components/IconWithBackground";

interface Props {
  systemId: number;
}

export const SystemHealth: FC<Props> = ({ systemId }) => {
  const { t } = useTranslation("healthy");

  const state = useSelector((state: RootState) => {
    return state.systemsAttributes.state;
  });

  if (!state) return null;

  return (
    <CardContainer
      icon={
        <IconWithBackground
          icon={state.general.healthy ? <IconHealthy /> : <IconUnhealthy />}
          color={state.general.healthy ? "green" : "red"}
        />
      }
      title={state.general.healthy ? t("titleActive") : t("titleInactive")}
      subtitle={
        state.general.healthy ? t("subtitleActive") : t("subtitleInactive")
      }
    >
      <></>
    </CardContainer>
  );
};
