import { FC, useCallback, useEffect } from "react";
import { Modal } from "antd";
import styles from "./LogoutModal.module.css";
import { LMNewButton } from "../../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import { Body1 } from "../../../components/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import { clearUser } from "../utils";

interface Props {
  open: boolean;
  onClose: () => void;
}

export const LogoutModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation("auth");
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    clearUser();
    logout({ returnTo: window.location.origin });
  }, [logout]);

  useEffect(() => {
    if (!open) return;
    const timeout = setTimeout(() => {
      handleLogout();
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [open, handleLogout]);

  return (
    <Modal
      open={open}
      footer={null}
      onCancel={onClose}
      width={400}
      className={styles.container}
      title={t("logoutTitle")}
    >
      <Body1 className={styles.content}>{t("logoutDescription")}</Body1>

      <div className={styles.buttons}>
        <LMNewButton onClick={handleLogout} className={styles.button}>
          {t("login")}
        </LMNewButton>
      </div>
    </Modal>
  );
};
