import { useCallback, useContext, useState } from "react";
import { ApiStatuses } from "../../app/types";
import { AbilityBuilder, AnyMongoAbility, Ability } from "@casl/ability";
import { Auth0Context } from "@auth0/auth0-react";
import jwt_decode from "jwt-decode";
import { axios } from "./utils";
import { useEffect } from "react";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../casl";
import { LoaderWrapper } from "../../components";
import { getToken, LogoutModal } from "../auth";
import { AppSystemContainer } from "../systems";
import { AxiosHeaders } from "axios";

interface IToken {
  permissions: string[];
}

const setupAbilities = (scopes: string[], ability: AnyMongoAbility) => {
  const { can, rules } = new AbilityBuilder(Ability);
  scopes.forEach((scope) => {
    const values = scope.split(/:(.*)/s);
    /**
     * manage is reserved word in CASL, it allows all actions.
     */
    let action = values[1];
    const target = values[0];
    if (action === "admin") {
      action = "manage";
      can(action, target);
      return;
    }
    if (action === "manage") {
      action = "managing";
    }
    can(action, target);
  });
  ability.update(rules);
};

const WithAxios = () => {
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(true);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
  } = useContext(Auth0Context);
  const ability = useAbility(AbilityContext);

  const loadToken = useCallback(async () => {
    if (isLoading) return;
    if (!isAuthenticated) {
      setLoading(false);
      return loginWithRedirect();
    }
    const accessToken = await getToken(getAccessTokenSilently);

    setToken(accessToken);
  }, [getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (!token) return;
    axios.interceptors.request.use(
      async (config) => {
        const newHeaders = {
          ...(config.headers || {}),
          Authorization: `Bearer ${token}`,
        } as unknown as AxiosHeaders;
        return {
          ...config,
          headers: newHeaders,
        };
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      async (config) => {
        return config;
      },
      (error) => {
        if (error.response?.status === 401) {
          setLogoutModalOpen(true);
        }
        return Promise.reject(error);
      }
    );
    setLoading(false);
  }, [token]);

  useEffect(() => {
    loadToken();
  }, [loadToken]);

  const setupAbilityByToken = useCallback(async () => {
    if (!token) return;
    const tokenDecoded: IToken = jwt_decode(token);
    setupAbilities(tokenDecoded.permissions, ability);
  }, [token, ability]);

  useEffect(() => {
    setupAbilityByToken();
  }, [setupAbilityByToken]);

  return (
    <>
      <LoaderWrapper
        status={loading ? ApiStatuses.loading : ApiStatuses.success}
      >
        {!loading ? <AppSystemContainer /> : <></>}
      </LoaderWrapper>
      <LogoutModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
      />
    </>
  );
};

export default WithAxios;
