import { FC, memo, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { useLocation } from "react-router-dom";
import { Routes } from "../../layout/Routes";
import { actions, fetchList } from "./slice";
import { AppDispatch, RootState } from "../../app/store";
import { connect, MessageKind, MessageOperation } from "../signalR";
import { keysActions } from "../keys";
import { monitoringActions } from "../monitoring";
import { monitorActions } from "../monitors";
import { updateShipping } from "../orders";
import { systemsActions } from "../systems";
import { ApiStatuses } from "../../app/types";
import { systemsAttributesActions } from "../systemAttributes";
import { tenantsActions } from "../tenants";
import { saveLastPathname } from "../auth";
import { AbilityContext } from "../casl";
import { fetchConfig } from "../config";

const Inner: FC = memo(() => {
  return <Routes />;
});

const getSystemId = (pathname: string) => {
  const paramsArray = pathname.split("/");
  return Number(paramsArray[1]);
};

export const AppSystemContainer = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [connection, setConnection] = useState<HubConnection>();
  const ability = useContext(AbilityContext);

  const location = useLocation();

  const status = useSelector((state: RootState) => {
    return state.systems.status;
  });

  const handleFetchSystemsAndConfig = useCallback(async () => {
    try {
      await dispatch(fetchConfig()).unwrap();
    } catch (error) {
      console.error("Error fetching system config");
    }
    dispatch(fetchList(ability.can("get", "systems")));
  }, [dispatch, ability]);

  useEffect(() => {
    handleFetchSystemsAndConfig();
  }, [handleFetchSystemsAndConfig]);

  useEffect(() => {
    saveLastPathname(location.pathname);
    if (status !== ApiStatuses.success) return;
    const systemId = getSystemId(location.pathname);
    dispatch(actions.setSelectedSystem(systemId));
    dispatch(systemsAttributesActions.setSystemId(systemId));
  }, [location.pathname, dispatch, status]);

  useEffect(() => {
    if (connection === undefined) {
      const _connection = connect();
      setConnection(_connection);
    }
    if (
      !connection ||
      connection.state === HubConnectionState.Connected ||
      connection.state === HubConnectionState.Connecting
    ) {
      return;
    }
    connection
      .start()
      .then(() => {
        connection.on(
          MessageKind.KeyEvent,
          (operation: MessageOperation, keyString: string) => {
            dispatch(keysActions.updateList({ keyString, operation }));
          }
        );
        connection.on(
          MessageKind.MediaEvent,
          (operation: MessageOperation, mediaString: string) => {
            dispatch(monitoringActions.updateList({ mediaString, operation }));
          }
        );
        connection.on(
          MessageKind.MonitorEvent,
          (operation: MessageOperation, monitorString: string) => {
            dispatch(monitorActions.updateList({ monitorString, operation }));
          }
        );
        connection.on(
          MessageKind.ShippingEvent,
          (operation: MessageOperation, shippingString: string) => {
            dispatch(updateShipping({ shippingString, operation }));
          }
        );
        connection.on(
          MessageKind.SystemEvent,
          (operation: MessageOperation, systemString: string) => {
            dispatch(
              systemsActions.updateSystemFromSignalR({
                systemString,
                operation,
              })
            );
          }
        );
        connection.on(
          MessageKind.TenantEvent,
          (operation: MessageOperation, tenantString: string) => {
            dispatch(
              tenantsActions.updateTenantFromSignalR({
                tenantString,
                operation,
              })
            );
          }
        );
      })
      .catch((e) => {
        console.log("error", e);
      });
  }, [dispatch, connection?.state, connection]);

  if (!connection) return <></>;

  return <Inner />;
};
