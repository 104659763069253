import { useTranslation } from "react-i18next";
import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "antd";
import classNames from "classnames/bind";
import { MenuProps } from "rc-menu";
import { useCallback, useEffect, useState } from "react";
import { ApiStatuses } from "../../../app/types";
import { LoaderWrapper } from "../../../components";
import {
  IconDropdownArrow,
  IconLanguage,
  IconLogout,
  IconUser,
} from "../../../assets";
import { clearUser } from "../utils";
import { saveLanguage } from "../../i18n/utils";
import { IconUserSettings } from "../../../assets/Sprites";
import { useLink } from "../../../components/LMLink";
import styles from "./UserInfo.module.css";

const cx = classNames.bind(styles);

export const UserInfo = () => {
  const { getIdTokenClaims, isAuthenticated, logout } = useAuth0();
  const { t, i18n } = useTranslation("auth");
  const navigate = useLink();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<IdToken>();
  const [open, setOpen] = useState(false);

  const fetchClaims = useCallback(async () => {
    if (!isAuthenticated) {
      setLoading(false);
      return;
    }
    const timeout = setTimeout(() => {
      getIdTokenClaims().then((idTokenClaims) => {
        if (!idTokenClaims) {
          setLoading(false);
          return;
        }
        setUser(idTokenClaims);
        setLoading(false);
      });
    }, 500);
    return () => clearTimeout(timeout);
  }, [getIdTokenClaims, isAuthenticated]);

  useEffect(() => {
    fetchClaims();
  }, [fetchClaims]);

  const handleLogout = () => {
    clearUser();
    logout();
  };

  const handleChangeLanguage = (language: string) => {
    saveLanguage(language);
    i18n.changeLanguage(language);
  };

  const handleNavigateToProfile = () => {
    navigate("/profile");
  };

  const items: MenuProps["items"] = [
    {
      label: t("account"),
      key: t("account"),
      onClick: () => handleNavigateToProfile(),
      itemIcon: <IconUserSettings />,
      className: styles.item,
    },
    {
      label: (
        <div className={styles["language-title"]}>
          <IconLanguage />
          {t("language")}
        </div>
      ),
      key: t("language"),
      className: styles.item,
      children: [
        {
          label: "English",
          key: "en",
          onClick: () => handleChangeLanguage("en"),
          itemIcon: <img src="/images/en.png" alt="en" />,
          className: styles.item,
        },
        {
          label: "עברית",
          key: "he",
          onClick: () => handleChangeLanguage("he"),
          itemIcon: <img src="/images/he.png" alt="he" />,
          className: styles.item,
        },
      ],
    },
    {
      label: t("logout"),
      key: t("logout"),
      onClick: () => handleLogout(),
      itemIcon: <IconLogout />,
      className: styles.item,
    },
  ];

  return (
    <LoaderWrapper status={loading ? ApiStatuses.loading : ApiStatuses.success}>
      <>
        {user && (
          <Dropdown
            menu={{ items, className: styles.menu }}
            className={styles.dropdown}
            onOpenChange={setOpen}
          >
            <div className={cx({ label: true, active: open })}>
              <IconUser />
              <span className={cx({ text: true })}>{user.name}</span>
              <span className={cx({ "dropdown-icon": true, rotated: open })}>
                <IconDropdownArrow
                  stroke={
                    open ? "var(--color-primary-80)" : "var(--color-gray-70)"
                  }
                />
              </span>
            </div>
          </Dropdown>
        )}
      </>
    </LoaderWrapper>
  );
};
