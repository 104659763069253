import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../app/store";

export const useLink = () => {
  const navigate = useNavigate();
  const { selectedSystem } = useSelector((state: RootState) => {
    return state.systems;
  });

  return (to: string) => {
    if (!selectedSystem) {
      navigate(to);
      return;
    }
    navigate(`/${selectedSystem.id}${to}`);
  };
};
