import { createSlice } from '@reduxjs/toolkit';
import { IAuthSliceState } from './types';

export const initialState: IAuthSliceState = {
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
  },
});

export const auth = slice.reducer;
export const actions = slice.actions;