import { FC, useCallback } from "react";
import classNames from "classnames/bind";
import { isControlModeAuto } from "../utils";
import { LMNewButton } from "../../../components/LMNewButton";
import { useTranslation } from "react-i18next";
import {
  IconAutoMode,
  IconManualMode,
  IconManualControlAction,
  IconAutoControlAction,
  IconCancelOrder,
} from "../../../assets";
import { Button2 } from "../../../components/Typography";
import { switchMode } from "../api";
import styles from "./ControlMode.module.css";
import { EControlStatus, IManualControl } from "../types";

const cx = classNames.bind(styles);

interface Props {
  mode?: IManualControl;
  serialNumber: string;
  onAutoSuccess: () => void;
  onError: () => void;
  onConfirmationOpen: () => void;
  onConfirmationClose: () => void;
}

export const ControlMode: FC<Props> = ({
  mode,
  serialNumber,
  onAutoSuccess,
  onError,
  onConfirmationOpen,
  onConfirmationClose,
}) => {
  const { t } = useTranslation("control");
  const isAutoMode = isControlModeAuto(mode);

  const handleSwitchToAuto = useCallback(async () => {
    try {
      await switchMode(serialNumber, false);
      onAutoSuccess();
      onConfirmationClose();
    } catch {
      onError();
    }
  }, [serialNumber, onAutoSuccess, onConfirmationClose, onError]);

  const getTitle = useCallback(() => {
    if (!mode) return t("autoMode");
    if (mode.status === EControlStatus.Pending) return t("pendingMode");
    return t("manualMode");
  }, [mode, t]);

  const getAction = useCallback(() => {
    if (!mode) {
      return (
        <LMNewButton
          color="default"
          onClick={onConfirmationOpen}
          className={styles.button}
          iconRight={<IconManualControlAction />}
        >
          {t("switchToManual")}
        </LMNewButton>
      );
    }
    if (mode.status === EControlStatus.Running) {
      return (
        <LMNewButton
          color="default"
          onClick={handleSwitchToAuto}
          className={styles.button}
          iconRight={<IconAutoControlAction />}
        >
          {t("switchToAuto")}
        </LMNewButton>
      );
    }
    return (
      <LMNewButton color="danger" onClick={handleSwitchToAuto}>
        {t("cancel")}
        <IconCancelOrder />
      </LMNewButton>
    );
  }, [mode, t, handleSwitchToAuto, onConfirmationOpen]);

  return (
    <div className={styles.container}>
      <div className={styles.mode}>
        <div
          className={cx({
            "icon-container": true,
            auto: isAutoMode,
          })}
        >
          {isAutoMode ? <IconAutoMode /> : <IconManualMode />}
        </div>
        <Button2>{getTitle()}</Button2>
      </div>
      <div className={styles["button-container"]}>{getAction()}</div>
    </div>
  );
};
