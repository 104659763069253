import { useTranslation } from "react-i18next";
import styles from "./SystemMetrics.module.css";
import { Body2, Button2, H3 } from "../../../components/Typography";
import { RootState } from "../../../app/store";
import { useSelector } from "react-redux";
import { ApiStatuses } from "../../../app/types";
import { StateErrorBox } from "../../systemAttributes";

export const SystemMetrics = () => {
  const { t } = useTranslation("system");

  const status = useSelector((state: RootState) => {
    return state.systemsAttributes.metricsStatus;
  });

  const metrics = useSelector((state: RootState) => {
    return state.systemsAttributes.metrics;
  });

  if (status === ApiStatuses.fail) {
    return <StateErrorBox />;
  }

  return (
    <div className={styles.container}>
      <H3>{t("metrics")}</H3>
      <div className={styles.content}>
        {metrics.map((metric) => (
          <div className={styles.item} key={metric.name}>
            <Body2>{metric.name}:</Body2>
            <Button2>{metric.valueDisplay}</Button2>
            <div className={styles.line}></div>
          </div>
        ))}
      </div>
    </div>
  );
};
