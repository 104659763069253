import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../app/store";
import { IconSystemRunning } from "../../../assets";
import { CardContainer } from "../../../components/CardContainer";
import { IconWithBackground } from "../../../components/IconWithBackground";

export const OrchestratorStatus: FC = () => {
  const { t } = useTranslation("orchestrator");

  const pause = useSelector((state: RootState) => {
    return state.systemsAttributes.state?.general.pause;
  });

  return (
    <>
      <CardContainer
        icon={
          <IconWithBackground
            icon={
              <IconSystemRunning
                stroke={pause ? "var(--color-yellow-70)" : undefined}
              />
            }
            color={pause ? "yellow" : "green"}
          />
        }
        title={t("title")}
        subtitle={pause ? t("paused") : t("running")}
      >
        <></>
      </CardContainer>
    </>
  );
};
