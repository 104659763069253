import { FC } from "react";
import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import {
  OrchestratorStatus,
  SystemHealth,
  SystemTasks,
  StateErrorBox,
  SystemMode,
} from "../../modules/systemAttributes";
import { ISystemDto } from "../../modules/systems";
import { SystemActivityGraph } from "../../modules/systemAttributes/SystemActivityGraph";
import { AcceptNewOrders } from "../../modules/tenants";
import { RootState } from "../../app/store";
import { ApiStatuses } from "../../app/types";
import styles from "./Home.module.css";

const cx = classNames.bind(styles);

interface Props {
  system: ISystemDto;
}

export const Home: FC<Props> = ({ system }) => {
  const enabledTasks = useSelector((state: RootState) => {
    return state.systemsAttributes.state?.enabledTasks;
  });

  const status = useSelector((state: RootState) => {
    return state.systemsAttributes.status;
  });

  return (
    <>
      <div className={styles["grid-container"]}>
        <div className={styles["grid-item"]}>
          <AcceptNewOrders system={system} />
        </div>
        {status === ApiStatuses.fail ? (
          <div className={cx({ "grid-item": true, error: true })}>
            <StateErrorBox />
          </div>
        ) : (
          <>
            <div className={styles["grid-item"]}>
              <SystemHealth systemId={system.id} />
            </div>
            <div className={styles["grid-item"]}>
              <OrchestratorStatus />
            </div>
            {enabledTasks && (
              <div className={styles["grid-item"]}>
                <SystemTasks systemId={system.id} enabledTasks={enabledTasks} />
              </div>
            )}
            <div className={styles["grid-item"]}>
              <SystemMode serialNumber={system.serialNumber} />
            </div>
          </>
        )}
        {/* <div className={styles["grid-item"]}>
          <MachineSpeed system={system} />
        </div> */}
      </div>
      <div className={styles.graph}>
        <SystemActivityGraph tenantId={system.tenantId} />
      </div>
    </>
  );
};
