import { FC } from "react";
import { Link, LinkProps } from "react-router-dom";
import { SystemsDecorator } from "../../modules/systems";

export const LMLink: FC<LinkProps> = ({ to, ...rest }) => {
  return (
    <SystemsDecorator>
      {(system) => <Link to={`/${system.id}${to}`} {...rest} />}
    </SystemsDecorator>
  );
};
