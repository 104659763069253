import { FC } from "react";
import { useTranslation } from "react-i18next";
import { IStation } from "../types";
import { ApiStatuses, Nullable } from "../../../app/types";
import { IconSystemStationsWarning } from "../../../assets";
import { Body2 } from "../../../components/Typography";
import { SystemStationsLine, getStationLength } from "./SystemStationLine";
import styles from "./SystemStations.module.css";

interface Props {
  stations: IStation[];
  selectedStation: Nullable<IStation>;
  status: ApiStatuses;
}

const getMaxLengthOfStations = (stations: IStation[]) => {
  const sideALength = getStationLength(stations, "A");
  const sideBLength = getStationLength(stations, "B");
  return sideALength > sideBLength ? sideALength : sideBLength;
};

export const SystemStations: FC<Props> = ({
  stations,
  selectedStation,
  status,
}) => {
  const { t } = useTranslation("system");

  const calculatePlatformWidth = () => {
    const stationLength = getMaxLengthOfStations(stations);
    if (stationLength <= 5) {
      return 694;
    }
    const difference = stationLength - 5;
    return 694 + difference * 122;
  };

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.platform}
          style={{ width: `${calculatePlatformWidth()}px` }}
        >
          {status === ApiStatuses.fail && (
            <div className={styles["platform-warning-container"]}>
              <IconSystemStationsWarning /> <Body2>{t("warning")}</Body2>
            </div>
          )}
          <div className={styles.stations}>
            <div className={styles["pallets-head"]} />
            <div className={styles.pallets}>
              <div className={styles.left}>
                <SystemStationsLine
                  selectedStation={selectedStation}
                  stations={stations}
                  side="A"
                />
              </div>
              <div className={styles.center}></div>
              <div className={styles.right}>
                <SystemStationsLine
                  selectedStation={selectedStation}
                  stations={stations}
                  side="B"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
