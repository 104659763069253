import { AxiosPromise } from 'axios';
import { IApiList } from '../../app/types';
import { axios } from '../axios';
import { IIndexingRequest } from '../indexing';
import { IMaintenance, IMaintenanceProduct, IOperation, IStockCompare } from './types';
import { getPagination } from '../../app/utils';

export const getMaintenances = (systemId: number, page: number, pageSize: number): AxiosPromise<IApiList<IMaintenance>> => {
  return axios.get(`/maintenance?systemId=${systemId}${getPagination(page, pageSize)}&orderBy=createdOn&sortBy=desc`);
}

export const getOperation = (maintenanceId: string): AxiosPromise<IOperation[]> => {
  return axios.get(`/maintenance/${maintenanceId}/operations`);
}

export const getCompare = (maintenanceId: string): AxiosPromise<IStockCompare> => {
  return axios.get(`/maintenance/${maintenanceId}/compare`);
}

export const getProducts = (operationId: number): AxiosPromise<IMaintenanceProduct[]> => {
  return axios.get(`/maintenance/operations/${operationId}/products`);
}

export const downloadReport = (maintenanceId: string): AxiosPromise<void> => {
  return axios.get(`/maintenance/${maintenanceId}/report`);
}

export const getIndexingRequestsList = (maintenanceId: string): AxiosPromise<IApiList<IIndexingRequest>> => {
  return axios.get(`/indexes?maintenanceId=${maintenanceId}&pageSize=100&page=1&orderBy=createdOn&sortBy=Asc`);
}